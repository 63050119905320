import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';

import { receiveProducts } from '@ttstr/actions';
import { Container, LoadingSpinner, Masonry, useIntl } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';
import FilteredMasonry from './FilteredMasonry';

const ProductListing: React.FC = () => {
  const { t } = useTranslation();
  const { loading, products } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveProducts();
  }, [language]);

  return (
    <article>
      {/* <Container fluid className="header-image" /> */}
      <Container className="list-page mb-5">
        <Helmet>
          <title>{t(`PRODUCTS.TITLE`)}</title>
          <body className="product-listing-page" />
        </Helmet>

        <div className="text-center">
          <img src={require('./assets/images/logo.png')} alt="" className="img-fluid w-100 my-5" />
        </div>

        {/* set empty h1 with height as spacer */}
        <h1 className="superhead text-white mt-5 mb-3" style={{ height: '72px' }}>
          {''}
        </h1>

        {/* // PRODUCT LISTING NEW ALBUM */}

        <div className="text-center">
          <img src={require('./assets/images/empor_release_header.jpg')} alt="" className="img-fluid w-100 my-2" />
        </div>

        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
        ) : products.length === 0 ? ( // if no products
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show products
          <Masonry
            id="product-listing"
            products={products.filter((t) => t.category_ids.includes(4410))}
            columnClassName="col-sm-12 col-md-6 col-lg-6"
            filters="off"
            showFormerPrice
          />
        )}

        {/* // PRODUCT LISTING Cremant */}

        <div className="text-center">
          <img src={require('./assets/images/cremant_header.jpg')} alt="" className="img-fluid w-100 my-2" />
        </div>

        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
        ) : products.length === 0 ? ( // if no products
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show products
          <Masonry
            id="product-listing"
            products={products.filter((t) => t.category_ids.includes(4413))}
            columnClassName="col-sm-12 col-md-6 col-lg-6"
            filters="off"
            showFormerPrice
          />
        )}

        {/* // PRODUCT LISTING STRAIGHT CLOTH */}

        <div className="text-center">
          <img src={require('./assets/images/clothing_straight_header.jpg')} alt="" className="img-fluid w-100 my-2" />
        </div>

        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
        ) : products.length === 0 ? ( // if no products
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show products
          <Masonry
            id="product-listing"
            products={products.filter((t) => t.category_ids.includes(4411))}
            columnClassName="col-sm-12 col-md-6 col-lg-6"
            filters="off"
            showFormerPrice
          />
        )}

        {/* // PRODUCT LISTING ACCESSORIES */}

        <div className="text-center">
          <img src={require('./assets/images/accessoires_header.jpg')} alt="" className="img-fluid w-100 my-2" />
        </div>

        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
        ) : products.length === 0 ? ( // if no products
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show products
          <Masonry
            id="product-listing"
            products={products.filter((t) => t.category_ids.includes(4412))}
            columnClassName="col-sm-12 col-md-6 col-lg-6"
            filters="off"
            showFormerPrice
          />
        )}

        {/* // PRODUCT LISTING MUSIC */}

        <div className="text-center">
          <img src={require('./assets/images/5_music.jpg')} alt="" className="img-fluid w-100 my-2" />
        </div>

        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
        ) : products.length === 0 ? ( // if no products
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show products
          <Masonry
            id="product-listing"
            products={products.filter((t) => t.category_ids.includes(4414))}
            columnClassName="col-sm-12 col-md-6 col-lg-6"
            filters="off"
            showFormerPrice
          />
        )}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products, loading } = state.Products;
  return {
    products,
    loading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
};

export default React.memo(ProductListing);
