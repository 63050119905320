import classNames from 'classnames';
import React from 'react';
import { Helmet } from 'react-helmet';

import { useTranslation } from 'react-i18next';
import { Link, matchPath, NavLink as RouterNavLink, useLocation } from 'react-router-dom';

import Col, { ColProps } from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import FormGroup from 'reactstrap/lib/FormGroup';
import Nav from 'reactstrap/lib/Nav';
import Navbar, { NavbarProps } from 'reactstrap/lib/Navbar';
import NavbarBrand from 'reactstrap/lib/NavbarBrand';
import NavItem from 'reactstrap/lib/NavItem';
import NavLink from 'reactstrap/lib/NavLink';
import Row from 'reactstrap/lib/Row';

import { AppState } from '@ttstr/reducers';
import { isAbsoluteURL, useCombinedRefs, useShallowEqualSelector, useToggle } from '@ttstr/utils';
import { useAuth } from '@ttstr/components/Auth/AuthContext';
import LanguageSwitcher from '@ttstr/components/Intl/LanguageSwitcher';
import CartListing from '@ttstr/components/ShoppingCart/CartListing';
import SimpleCart from '@ttstr/components/ShoppingCart/SimpleCart';
import ShoppingFinisherModal from '@ttstr/components/ShoppingCart/ShoppingFinisherModal';
import CartExpiredModal from '@ttstr/components/ShoppingCart/CartExpiredModal';
import ProductFormModal from '@ttstr/components/VariantListing/ProductFormModal';

interface OwnProps extends NavbarProps {
  logoLinkTo?: string;
  /**
   * The provides value can be an image-URL or a full ReactNode which replaces the default navbar-brand
   */
  logo?: string | React.ReactNode;
  logoxs?: string | React.ReactNode;
  dark?: boolean;
  customerMenuRef?: React.Ref<HTMLLIElement>;
  mainMenuRef?: React.Ref<HTMLDivElement>;
  navbarRef?: React.Ref<HTMLUListElement>;
  /**
   * Position of the main menu fly-out (the offcanvas menu itself, not the Toggler)
   */
  mainMenuPosition?: string;
  mainMenuColumnProps?: ColProps;
  secondaryMenuColumnProps?: ColProps;
  brandContainerColumnProps?: ColProps;
  /**
   * Closes the main menu whenever a click in the DOM is registered, regardless if the click happened inside the main menu or not.
   */
  alwaysCloseMainMenu?: boolean;
  cartDark?: boolean;
  offcanvasDark?: boolean;
  cartIcon?: React.ReactNode;
  closeIcon?: React.ReactNode;
  togglerIcon?: React.ReactNode;
  togglerClassName?: string;
  simpleCartClassName?: string;
  showRegister?: boolean;
  fluid?: string | boolean;
  showLanguageSwitcher?: boolean;
}

type Props = Readonly<OwnProps>;

export const defaultTogglerIcon = (
  <span className="hamburger-box">
    <span className="hamburger-inner" />
  </span>
);

const defaultMainMenuColumnProps: Omit<ColProps, 'ref'> = {
  xs: 'auto',
  md: true,
};

const defaultSecondaryMenuColumnProps: Omit<ColProps, 'ref'> = {
  className: 'position-static w-100',
};

const defaultBrandContainerColumnProps: Omit<ColProps, 'ref'> = {
  xs: 'auto',
};

// eslint-disable-next-line react/jsx-no-literals
const defaultCloseIcon = <>&times;</>;

const Navigation: React.FC<Props> = ({
  customerMenuRef,
  mainMenuRef,
  navbarRef,
  mainMenuPosition = 'left',
  mainMenuColumnProps = defaultMainMenuColumnProps,
  brandContainerColumnProps = defaultBrandContainerColumnProps,
  secondaryMenuColumnProps = defaultSecondaryMenuColumnProps,
  alwaysCloseMainMenu,
  children,
  cartDark,
  offcanvasDark,
  cartIcon,
  logo,
  logoxs,
  logoLinkTo,
  togglerClassName = 'hamburger hamburger--squeeze',
  simpleCartClassName,
  togglerIcon = defaultTogglerIcon,
  closeIcon,
  showRegister = true,
  showLanguageSwitcher = true,
  fluid = true,
  ...props
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { loggedIn, loggingIn } = useAuth();
  const menuRef = React.useRef<HTMLElement>();
  const cartMenuRef = React.useRef<HTMLElement>();
  const combinedMainMenuRef = useCombinedRefs(menuRef, mainMenuRef);

  const [isMenuOpen, toggleIsMenuOpen, setIsMenuOpen] = useToggle(false);
  const [cartIsOpen, toggleCartIsOpen, setCartIsOpen] = useToggle(false);

  const { hasItems, isLocked, hasUnsubmittedReplies, noCheckoutMessage } = useShallowEqualSelector(mapStateToProps);

  const toggle = (e: React.MouseEvent<HTMLElement>) => {
    // prevents the document event handler fom being called
    e.nativeEvent.stopImmediatePropagation();
    toggleIsMenuOpen();
    setCartIsOpen(false);
  };

  const toggleCart = (e: React.MouseEvent<HTMLElement>) => {
    // prevents the document event handler fom being called
    e.nativeEvent.stopImmediatePropagation();
    // prevents the nav-link from being followed
    e.preventDefault();
    toggleCartIsOpen();
    setIsMenuOpen(false);
  };

  React.useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (alwaysCloseMainMenu || (menuRef.current && !menuRef.current.contains(e.target as Node))) {
        setIsMenuOpen(false);
      }
      if (cartMenuRef.current && !cartMenuRef.current.contains(e.target as Node)) {
        setCartIsOpen(false);
      }
    };

    const handleEscapeKey = (e: KeyboardEvent) => {
      if (e.key !== 'Escape') return;
      setIsMenuOpen(false);
      setCartIsOpen(false);
    };

    document.addEventListener('click', handleOutsideClick, false);
    document.addEventListener('keydown', handleEscapeKey, false);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  React.useEffect(() => {
    setIsMenuOpen(false);
    setCartIsOpen(false);
  }, [location.pathname]);

  const logoLinkToIsAbsolute = React.useMemo(() => isAbsoluteURL(logoLinkTo), [logoLinkTo]);

  return (
    <Navbar {...props}>
      <Helmet>{(isMenuOpen || cartIsOpen) && <body data-menuopen={true} />}</Helmet>
      <CartExpiredModal closeIcon={closeIcon} />
      <ProductFormModal />
      <ShoppingFinisherModal closeIcon={closeIcon} visible={!hasUnsubmittedReplies} />

      <Container fluid={fluid}>
        <Row className="flex-nowrap" noGutters>
          <Col {...brandContainerColumnProps} className="text-center navbar-brand-container">
            <NavbarBrand
              tag={logoLinkToIsAbsolute ? 'a' : Link}
              {...(logoLinkToIsAbsolute ? { href: logoLinkTo, rel: 'noopener noreferrer' } : { to: logoLinkTo || '/' })}
              title={t(`NAVIGATION.GOTO_HOME`)}
              className="m-0"
            >
              {/* <span className='d-none d-md-block'>MEUTE</span>
              <span className='d-md-none'>VH</span> */}

              {typeof logo === 'string' ? (
                <img src={logo} alt={t(`NAVIGATION.SHOP_LOGO`)} loading="lazy" className="d-none d-md-block" />
              ) : (
                logo
              )}
              {typeof logoxs === 'string' ? (
                <img src={logoxs} alt={t(`NAVIGATION.SHOP_LOGO`)} loading="lazy" className="d-md-none" />
              ) : (
                logoxs
              )}
            </NavbarBrand>
          </Col>
          <Col
            {...secondaryMenuColumnProps}
            className={classNames('secondary-menu', secondaryMenuColumnProps?.className)}
          >
            <ul className="menu navbar-nav navbar-uncollapsible justify-content-end" ref={navbarRef} />
            <ul className="navbar-nav navbar-uncollapsible justify-content-end">
              {showRegister && !loggedIn && !loggingIn && (
                <NavItem>
                  <NavLink tag={RouterNavLink} to="/customer/register">
                    {t('CUSTOMER.MEMBERSHIP')}
                  </NavLink>
                </NavItem>
              )}
              {showLanguageSwitcher && <LanguageSwitcher />}
              <li className="customer-menu" ref={customerMenuRef} />
              <NavItem className="last cart" active={cartIsOpen}>
                <SimpleCart
                  className={simpleCartClassName}
                  onClick={toggleCart}
                  aria-controls="cart-menu"
                  aria-expanded={cartIsOpen}
                  aria-label={t('NAVIGATION.TOGGLE_CART')}
                  cartIcon={cartIcon}
                />
              </NavItem>
            </ul>
            <aside
              ref={cartMenuRef}
              id="cart-menu"
              className={classNames(
                'navbar-collapse offcanvas-collapse offcanvas-collapse-fixed offcanvas-collapse-right offcanvas-collapse-lg',
                { open: cartIsOpen, 'offcanvas-collapse-dark': offcanvasDark ?? props.dark }
              )}
            >
              <Container fluid className="my-2">
                <h5 className="my-3">{t(`CART.TITLE`)}</h5>
                <button type="button" aria-label={t('NAVIGATION.TOGGLE')} className="close" onClick={toggleCart}>
                  <span aria-hidden="true">{closeIcon || defaultCloseIcon}</span>
                </button>
              </Container>

              <Container fluid className="mb-3">
                <CartListing dark={cartDark ?? offcanvasDark ?? props.dark} />
              </Container>

              {!isLocked && hasItems && (
                <Container
                  fluid
                  className={classNames('sticky-bottom py-1', {
                    'd-none': matchPath(location.pathname, '/checkout'),
                  })}
                >
                  <FormGroup className="mb-0 flex-fill">
                    {/* TODO: put in CheckoutButton Component (here and Cart and ProductModal) */}
                    {!noCheckoutMessage ? (
                      <Link to="/checkout" className="btn btn-primary btn-lg btn-block ml-auto">
                        {t(`CART.GOTO_CHECKOUT`)}
                      </Link>
                    ) : (
                      <>
                        <Link
                          to="#"
                          className="btn btn-primary btn-lg btn-block ml-auto disabled"
                          aria-disabled
                          aria-invalid
                          aria-errormessage="no-checkout-message"
                        >
                          {t(`CART.GOTO_CHECKOUT`)}
                        </Link>
                        <p id="no-checkout-message" className="text-danger mt-2 ml-3">
                          {noCheckoutMessage}
                        </p>
                      </>
                    )}
                  </FormGroup>
                </Container>
              )}
            </aside>
          </Col>
          <Col {...mainMenuColumnProps} className="main-menu d-flex align-items-center">
            <Nav navbar>
              <NavItem>
                <NavLink
                  href="#"
                  onClick={toggle}
                  title={t('NAVIGATION.TOGGLE')}
                  className={classNames('nav-link', { 'is-active': isMenuOpen }, togglerClassName)}
                  aria-controls="main-menu"
                  aria-expanded={isMenuOpen}
                  aria-label={t('NAVIGATION.TOGGLE')}
                >
                  {togglerIcon}
                </NavLink>
              </NavItem>
            </Nav>

            <aside
              ref={combinedMainMenuRef}
              id="main-menu"
              className={classNames('navbar-collapse offcanvas-collapse', `offcanvas-collapse-${mainMenuPosition}`, {
                open: isMenuOpen,
                'offcanvas-collapse-dark': offcanvasDark ?? props.dark,
              })}
            >
              {children && <Nav navbar>{children}</Nav>}
            </aside>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
};

function mapStateToProps(state: AppState) {
  const { cart } = state.Cart;
  const isLocked = Boolean(cart?.cart_token);
  const items = cart?.items ? Object.values(cart.items) : [];
  const hasUnsubmittedReplies = items.some((item) => item.replies.some((reply) => !reply.submitted));
  const noCheckoutMessage = cart?.no_checkout_message;

  return {
    isLocked,
    hasItems: Boolean(items.length),
    hasUnsubmittedReplies,
    noCheckoutMessage,
  };
}

export default React.memo(Navigation);
